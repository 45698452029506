import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from "reactstrap";
import SVG from "react-inlinesvg";

const ITEMS = [
  {
    svg: "tiktok.svg",
    iconClasses: "fab fa-6x fa-tiktok",
    eventName: "tiktok",
    link: "https://www.tiktok.com/@alvesoaj",
    label: "tiktok.com/@alvesoaj",
  },
  {
    svg: "kwai.svg",
    iconClasses: "fab fa-6x fa-kwai",
    eventName: "kwai",
    link: "https://www.kwai.com/@alvesoaj",
    label: "KWAI: @alvesoaj",
  },
  {
    iconClasses: "fab fa-6x fa-youtube",
    eventName: "youtube",
    link: "https://youtube.com/alvesoaj",
    label: "youtube.com/alvesoaj",
  },
  {
    iconClasses: "fab fa-6x fa-twitter",
    eventName: "twitter",
    link: "https://twitter.com/alvesoaj",
    label: "twitter.com/alvesoaj",
  },
  {
    svg: "confraria-liberal.svg",
    iconClasses: "fas fa-6x fa-cube",
    eventName: "confraria-liberal",
    link: "https://www.confrarialiberal.com.br/search/label/x-aj-alves",
    label: "Postagens Confraria Liberal",
  },
  {
    svg: "bancada-conservadora.svg",
    iconClasses: "fas fa-6x fa-cube",
    eventName: "bancada-conservadora",
    link: "https://www.bancadaconservadora.com.br/search/label/x-aj-alves",
    label: "Postagens Bancada Conservadora",
  },
  {
    iconClasses: "fab fa-6x fa-facebook",
    eventName: "facebook",
    link: "https://facebook.com/alvesoaj",
    label: "facebook.com/alvesoaj",
  },
  {
    iconClasses: "fab fa-6x fa-instagram",
    eventName: "instagram",
    link: "https://instagram.com/alvesoaj",
    label: "instagram.com/alvesoaj",
  } /*
  {
    svg: "skoob.svg",
    iconClasses: "fas fa-6x fa-cube",
    eventName: "skoob",
    link: "https://www.skoob.com.br/perfil/alvesoaj",
    label: "skoob.com.br/perfil/alvesoaj",
  },
  {
    svg: "parler.svg",
    iconClasses: "fas fa-6x fa-cube",
    eventName: "parler",
    link: "https://parler.com/profile/alvesoaj",
    label: "parler.com/profile/alvesoaj",
  },*/,
];

const SocialLinks = (props) => {
  const { performGAEvent } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === ITEMS.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? ITEMS.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} ride="carousel">
      <CarouselIndicators items={ITEMS} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {ITEMS.map((item) => {
        return (
          <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.link}>
            <SVG src={`${process.env.PUBLIC_URL}/contact-bg.svg`} alt={item.label} className={[item.eventName, "img-fluid"].join(" ")} />
            <a
              className="contact-panel"
              href={item.link}
              target="_blank"
              onClick={(event) => {
                performGAEvent({
                  category: "social-promo",
                  action: "click",
                  label: item.eventName,
                });
              }}
            >
              {item.svg ? <SVG src={`${process.env.PUBLIC_URL}/${item.svg}`} alt={item.label} className={"img-fluid"} style={{ height: "96px", margin: "10px" }} /> : <i className={item.iconClasses} />}
              {item.label}
            </a>
            <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
          </CarouselItem>
        );
      })}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
};

export default SocialLinks;
