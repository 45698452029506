import React from "react";

const PLAN_LINK = "https://docs.google.com/document/d/1EcOiUebrkRMQRdDKkAbQSWXZdeq-FFHxAF4-GzPnuYc/edit?usp=sharing";

const Calendar = (props) => {
  const { performGAEvent } = props;

  const triggerEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "plan",
    });
  };

  return (
    <div className="contact-box">
      <a className="calendar" href={PLAN_LINK} target="_blank" onClick={triggerEvent}>
        <i className="fas fa-2x fa-rocket" />
        <strong>Plano de Governo 2021-2024</strong>
      </a>
    </div>
  );
};

export default Calendar;
