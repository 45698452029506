import React from "react";

const CALENDLY_LINK = "https://calendly.com/alvesoaj/chamada";

const Calendar = (props) => {
  const { performGAEvent } = props;

  const triggerEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "calendly30",
    });
  };

  return (
    <div className="contact-box">
      <a className="calendar" href={CALENDLY_LINK} target="_blank" onClick={triggerEvent}>
        <i className="far fa-2x fa-calendar-check" />
        <strong>Posso te ligar em vídeo? Agende aqui!</strong>
      </a>
    </div>
  );
};

export default Calendar;
