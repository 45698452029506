import React from "react";

const WIKI_LINK = "https://pt.wikipedia.org/wiki/Usu%C3%A1rio(a):Alvesoaj/Testes";

const AboutMe = (props) => {
  const { performGAEvent } = props;

  const triggerEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "wikipedia",
    });
  };

  return (
    <div className="contact-box">
      <a className="wikipedia" href={WIKI_LINK} target="_blank" onClick={triggerEvent}>
        <strong>Quem sou eu? Leia em:</strong>
        <i className="fab fa-2x fa-wikipedia-w" />
      </a>
    </div>
  );
};

export default AboutMe;
