import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Pop = (props) => {
  const [modal, setModal] = useState(props.status);

  const toggle = () => setModal(!modal);

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Vote 27! Apresentação!</ModalHeader>
      <ModalBody>
        <div class="embed-container">
          <iframe src="https://www.youtube.com/embed/Bf29f3sK3V8?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Pop;
