import React from "react";

const CONTACT_PATH = `${process.env.PUBLIC_URL}/alvesoaj.vcf`;

const VCFContacts = (props) => {
  const { performGAEvent } = props;

  const triggerEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "add-contact",
    });
  };

  return (
    <h4 className="add-contact">
      <i className="fa fa-plus"></i>
      <a href={CONTACT_PATH} onClick={triggerEvent} type="text/vcard">
        Adicionar aos Contatos
      </a>
    </h4>
  );
};

export default VCFContacts;
