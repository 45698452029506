import React from "react";

const CALENDAR_LINK = "https://calendar.google.com/calendar/embed?src=1rekth1iggb3r45fb74o3h414c%40group.calendar.google.com&ctz=America%2FFortaleza";

const Calendar = (props) => {
  const { performGAEvent } = props;

  const triggerEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "calendar",
    });
  };

  return (
    <div className="contact-box">
      <a className="calendar" href={CALENDAR_LINK} target="_blank" onClick={triggerEvent}>
        <i className="far fa-2x fa-calendar-alt" />
        <strong>Agenda do candidato</strong>
      </a>
    </div>
  );
};

export default Calendar;
