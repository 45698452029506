import React from "react";
import styled, { keyframes } from "styled-components";
import { merge, tada, flip } from "react-animations";

const WHATSAPP_LINK = "https://wa.me/4901748912907";
const FORMATTED_PHONE = "+49 0174 891 2907";
const TELEGRAM_LINK = "https://t.me/alvesoaj";

const tadaFlip = merge(tada, flip);
const animation = keyframes`${tadaFlip}`;

const BouncyA = styled.a`
  animation: 4s ${animation};
  animation-iteration-count: infinite;
`;

const ChatContacts = (props) => {
  const { performGAEvent } = props;

  const triggerWhatsappEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "whatsapp",
    });
  };

  const triggerTelegramEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "telegram",
    });
  };

  return (
    <div className="contact-box">
      <a className="whatsapp" href={WHATSAPP_LINK} target="_blank" onClick={triggerWhatsappEvent}>
        <i className="fab fa-2x fa-whatsapp" />
        <br />
        <small>Whatsapp</small>
      </a>
      {FORMATTED_PHONE}
      <a className="telegram" href={TELEGRAM_LINK} target="_blank" onClick={triggerTelegramEvent}>
        <small>Telegram</small>
        <br />
        <i className="fab fa-2x fa-telegram" />
      </a>
    </div>
  );
};

export default ChatContacts;
