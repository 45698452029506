import React from "react";
import ReactGA from "react-ga";

import VCFContacts from "./components/VCFContacts";
import ChatContacts from "./components/ChatContacts";
import WarRooms from "./components/WarRooms";
import Plan from "./components/Plan";
import AboutMe from "./components/AboutMe";
import Calendar from "./components/Calendar";
import Calendly from "./components/Calendly";
import SocialLinks from "./components/SocialLinks";
import Pop from "./components/Pop";

import "./App.css";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-29083921-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const App = (props) => {
  const performGAEvent = (eventObj) => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event(eventObj);
    }
  };

  return (
    <>
      <Pop status={false} />
      <div className="contacts" style={{ height: window.innerHeight }}>
        <VCFContacts performGAEvent={performGAEvent} />
        <div className="list-contact">
          <div className="contact-row">
            <AboutMe performGAEvent={performGAEvent} />
          </div>
          <div className="contact-row" style={{ display: "none" }}>
            <Plan performGAEvent={performGAEvent} />
          </div>
          <div className="contact-row">
            <ChatContacts performGAEvent={performGAEvent} />
          </div>
          <div className="contact-row" style={{ display: "none" }}>
            <WarRooms performGAEvent={performGAEvent} />
          </div>
          <div className="contact-row" style={{ display: "none" }}>
            <Calendar performGAEvent={performGAEvent} />
          </div>
          <div className="contact-row" style={{ display: "none" }}>
            <Calendly performGAEvent={performGAEvent} />
          </div>
          <div className="carousel-area">
            <SocialLinks performGAEvent={performGAEvent} />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
