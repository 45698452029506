import React from "react";

const WHATSAPP_LINK = "https://chat.whatsapp.com/Lp8PE4JzmOTHRagNq4ptti";
const TELEGRAM_LINK = "https://t.me/joinchat/Phhcqxvh2lrl91V5a2Wf3A";

const WarRooms = (props) => {
  const { performGAEvent } = props;

  const triggerWhatsappEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "whatsapp-war-room",
    });
  };

  const triggerTelegramEvent = (event) => {
    performGAEvent({
      category: "social-promo",
      action: "click",
      label: "telegram-war-room",
    });
  };

  return (
    <div className="contact-box">
      <a className="whatsapp" href={WHATSAPP_LINK} target="_blank" onClick={triggerWhatsappEvent}>
        <i className="fab fa-2x fa-whatsapp" />
        <br />
        <small>Whatsapp</small>
      </a>
      Grupo de Divulgação
      <a className="telegram" href={TELEGRAM_LINK} target="_blank" onClick={triggerTelegramEvent}>
        <small>Telegram</small>
        <br />
        <i className="fab fa-2x fa-telegram" />
      </a>
    </div>
  );
};

export default WarRooms;
